<template>
  <div class="center">
    <button @click="requestCert(row)" class="btn btn-default btn-with-icon" v-if="!row.is_ssl_cert_exists" title="Request Cert">
      <div class="btn-with-icon-content"><i class="fa fa-globe"></i></div>
    </button>
  </div>
</template>
<script>

export default {
  props: ['row', 'nested', 'xprops'],

  data () {
    return {
      disableArchive: false,
    }
  },

  mounted () {

  },

  methods: {
    requestCert (whitelable) {
      this.xprops.eventbus.$emit('requestCert', whitelable)
    },
  },
}
</script>
